exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-eyecare-js": () => import("./../../../src/pages/eyecare.js" /* webpackChunkName: "component---src-pages-eyecare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-makeup-js": () => import("./../../../src/pages/makeup.js" /* webpackChunkName: "component---src-pages-makeup-js" */),
  "component---src-pages-masks-js": () => import("./../../../src/pages/masks.js" /* webpackChunkName: "component---src-pages-masks-js" */),
  "component---src-pages-moisturizer-js": () => import("./../../../src/pages/moisturizer.js" /* webpackChunkName: "component---src-pages-moisturizer-js" */),
  "component---src-pages-serum-js": () => import("./../../../src/pages/serum.js" /* webpackChunkName: "component---src-pages-serum-js" */),
  "component---src-pages-toner-js": () => import("./../../../src/pages/toner.js" /* webpackChunkName: "component---src-pages-toner-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

